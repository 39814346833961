import http from "../base/httpcomponent";

class MenuItemClassificationDataService {
    getAll() {
        return http.get("/menuitemclassification/getall");
    }

    get(id) {
        return http.get(`/menuitemclassification/${id}`);
    }

    create(data) {
        return http.post("/menuitemclassification/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/menuitemclassification/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/menuitemclassification/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/menuitemclassification`);
    }
}

export default new MenuItemClassificationDataService();