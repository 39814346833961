import http from "../base/httpcomponent";

class LanguageDataService {
    getAll() {
        return http.get("/language/getall");
    }

    get(id) {
        return http.get(`/language/${id}`);
    }

    create(data) {
        return http.post("/language/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/language/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/language/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/language`);
    }
}

export default new LanguageDataService();