import React, { Component } from "react";
import MenuCategoryTypeService from "../../services/menucategorytype.service";

export class MenuCategoryType extends Component {
    constructor(props) {
        super(props);
        this.onChangeCategoryTypeName = this.onChangeCategoryTypeName.bind(this);
        this.updateCategoryType = this.updateCategoryType.bind(this);
        this.deleteCategoryType = this.deleteCategoryType.bind(this);

        this.state = {
            currentCategoryType: {
                menuCategoryTypeId: null,
                name: "",
            },
            message: ""
        };
    }

    componentDidMount() {
        this.getCategoryType(this.props.match.params.id);
    }

    onChangeCategoryTypeName(e) {
        const CategoryTypeName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentCategoryType: {
                    ...prevState.currentCategoryType,
                    name: CategoryTypeName
                }
            };
        });
    }

    getCategoryType(id) {
        MenuCategoryTypeService.get(id)
            .then(response => {
                this.setState({
                    currentCategoryType: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updateCategoryType() {
        MenuCategoryTypeService.update(
            this.state.currentCategoryType.menuCategoryTypeId,
            this.state.currentCategoryType
        )
            .then(response => {
                console.log(response.data);
                this.setState({
                    message: "The CategoryType was updated successfully!"
                });
                alert("CategoryType updated successfully!");
                this.props.history.push('/menu');
            })
            .catch(e => {
                console.log(e);
            });
    }

    deleteCategoryType() {
        MenuCategoryTypeService.delete(this.state.currentCategoryType.menuCategoryTypeId)
            .then(response => {
                console.log(response.data);
                alert("CategoryType removed successfully!")
                this.props.history.push('/menu')
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { currentCategoryType } = this.state;

        return (
            <div>
                {currentCategoryType ? (
                    <div className="edit-form">
                        <h4>CategoryType</h4>
                        <form>
                            <div className="form-group">
                                <label htmlFor="title">CategoryType Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={currentCategoryType.name}
                                    onChange={this.onChangeCategoryTypeName}
                                />
                            </div>
                        </form>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.deleteCategoryType}
                        >
                            Delete
                        </button>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={this.updateCategoryType}
                        >
                            Update
                        </button>
                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a CategoryType...</p>
                    </div>
                )}
            </div>
        );
    }
}