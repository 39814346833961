import http from "../base/httpcomponent";

class MenuCategoryTypeDataService {
    getAll() {
        return http.get("/menucategorytype/getall");
    }

    get(id) {
        return http.get(`/menucategorytype/${id}`);
    }

    create(data) {
        return http.post("/menucategorytype/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/menucategorytype/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/menucategorytype/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/menucategorytype`);
    }
}

export default new MenuCategoryTypeDataService();