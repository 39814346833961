import React, { Component } from "react";
import CmsPhraseDataService from "../../services/cmsphrase.service";
import cmsPageDataService from "../../services/cmspage.service";
import LanguageDataService from "../../services/language.service";
import { Link } from "react-router-dom";

export class MenuItemClassificationAdd extends Component {
    constructor(props) {
        super(props);
        this.onChangePhraseName = this.onChangePhraseName.bind(this);
        this.onChangePhraseKeyword = this.onChangePhraseKeyword.bind(this);
        this.onChangePhraseDescription = this.onChangePhraseDescription.bind(this);
        this.onChangePhraseContent = this.onChangePhraseContent.bind(this); 
        this.saveCmsPhrase = this.saveCmsPhrase.bind(this);
        this.newCmsPhrase = this.newCmsPhrase.bind(this);
        this.loadPhraseData = this.loadPhraseData.bind(this);
        this.onChangePageId = this.onChangePageId.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);

        this.state = {
            phraseId: null,
            phraseName: "",
            phraseKeyword: "",
            phraseDescription: "",
            phraseContent: "",
            languageId: "",
            pageId: "",
            pages: null,
            languages: null,
        };
    }

    componentDidMount() {
        this.loadPhraseData();
    }

    loadPhraseData() {

        cmsPageDataService.getAll()
            .then(response => {
                this.setState({
                    pages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        LanguageDataService.getAll()
            .then(response => {
                this.setState({
                    languages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }

    onChangePhraseName(e) {
        this.setState({
            phraseName: e.target.value
        });
    }

    onChangePhraseKeyword(e) {
        const phraseKeyword = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPhrase: {
                    ...prevState.currentPhrase,
                    phraseKeyword: phraseKeyword
                }
            };
        });
    }

    onChangePhraseDescription(e) {
        this.setState({
            phraseDescription: e.target.value
        });
    }

    onChangePhraseContent(e) {
        this.setState({
            phraseContent: e.target.value
        });
    }

    onChangePageId(e) {
        this.setState({
            pageId: e.target.value
        });
    }

    onChangeLanguage(e) {
        this.setState({
            languageId: e.target.value
        });
    }

    saveCmsPhrase() {
        var data = {
            phraseName: this.state.phraseName,
            phraseKeyword: this.state.phraseKeyword,
            phraseDescription: this.state.phraseDescription,
            phraseContent: this.state.phraseContent,
            Cms_Page_Id: this.state.pageId,
            Language_Id: this.state.languageId
        };

        console.log(data)

        CmsPhraseDataService.create(data)
            .then(response => {
                this.setState({
                    phraseName: response.data.phraseName,
                    phraseKeyword: response.data.phraseKeyword,
                    phraseDescription: response.data.phraseDescription,
                    phraseContent: response.data.phraseContent,
                    pageId: response.data.pageId,
                    languageId: response.data.languageId,
                });
                console.log(response.data);
                alert("Phrase created successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newCmsPhrase() {
        this.setState({
            phraseId: null,
            phraseName: "",
            phraseKeyword: "",
            phraseDescription: "",
            phraseContent: "",
            pageId: "",
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create Phrase</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new Phrase successfully!</h4>
                        <Link to={"/cms"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                        <div className="form-group">
                            <label htmlFor="title">Phrase Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                required
                                value={this.state.PhraseName}
                                onChange={this.onChangePhraseName}
                                name="title"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Phrase Keyword</label>
                            <input
                                type="text"
                                className="form-control"
                                id="phraseKeyword"
                                value={this.state.phraseKeyword}
                                onChange={this.onChangePhraseKeyword}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                required
                                value={this.state.PhraseDescription}
                                onChange={this.onChangePhraseDescription}
                                name="description"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Content</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="content"
                                required
                                value={this.state.phraseContent}
                                onChange={this.onChangePhraseContent}
                                name="description"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="pageId">Page</label>
                            <select
                                className="form-control"
                                id="pageId"
                                value={this.state.pageId}
                                onChange={this.onChangePageId}>
                                    <option value="0">Select Page</option>
                                    {this.state.pages &&
                                    this.state.pages.map((pageEntity, index) => (
                                        <option key={pageEntity.pageId} value={pageEntity.pageId}>{pageEntity.pageName}</option>
                                    ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="pageId">Language</label>
                            <select
                                className="form-control"
                                id="pageId"
                                value={this.state.languageId}
                                    onChange={this.onChangeLanguage}>
                                    <option value="0">Select Language</option>
                                {this.state.languages &&
                                        this.state.languages.map((languageEntity, index) => (
                                            <option key={languageEntity.languageId} value={languageEntity.languageId}>{languageEntity.languageName}</option>
                                    ))}
                            </select>
                        </div>

                            <button onClick={() =>this.saveCmsPhrase()} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
