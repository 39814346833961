import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CmsPageDataService from "./../services/cmspage.service";
import LanguageDataService from "./../services/language.service";
import CmsPhraseDataService from "./../services/cmsphrase.service";

export class CMS extends Component {
    static displayName = CMS.name;

      constructor(props) {
          super(props);

          this.onChangePhraseContent = this.onChangePhraseContent.bind(this);
          this.updatePhrase = this.updatePhrase.bind(this);
          this.onChangeLanguage = this.onChangeLanguage.bind(this);
          this.setActiveCmsPhrase = this.setActiveCmsPhrase.bind(this);          

          this.state = {
              cmsdata: [],
              pages: [],
              currentCmsPage: null,
              currentCmsPhrase: null,
              phraseContent: "",
              loading: true,
              currentIndex: 0,
              languageId: "",
              languages: null,
          };
      }

    componentDidMount() {
        this.retrieveCmsPages();
        LanguageDataService.getAll()
            .then(response => {
                this.setState({
                    languages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    retrieveCmsPages() {
        CmsPageDataService.getAll()
            .then(response => {
                this.setState({
                    pages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshList() {
        this.retrieveCmsPages();
        this.setState({
            currentCmsPage: null,
            currentCmsPhrase: null,
            currentIndex: -1
        });
    }

    setActiveCmsPage(tutorial, index) {
        this.setState({
            currentCmsPage: tutorial,
            currentIndex: index
        });
    }

    async setActiveCmsPhrase(phraseId) {

        await this.setState(prevState => ({
            currentCmsPhrase: null
        }));

        CmsPhraseDataService.get(phraseId, this.state.languageId)
            .then(response => {
                this.setState({
                    currentCmsPhrase: response.data
                });
                console.log(response.data);

                for (var i = 0; i < this.state.currentCmsPhrase.translationList.length; i++) {
                    if (this.state.currentCmsPhrase.translationList[i].language_Id === this.state.languageId) {
                        this.setState(prevState => ({
                            currentCmsPhrase: {
                                ...prevState.currentCmsPhrase,
                                phraseContent: this.state.currentCmsPhrase.translationList[i].phraseContent
                            }
                        }));
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onChangeLanguage(e) {
        await this.setState({
            languageId: e.target.value
        });
        console.log(this.state.languageId)
        this.refreshList();
    }

    onChangePhraseContent(e) {
        const phraseContent = e.target.value;
        
        this.setState(prevState => ({
            currentCmsPhrase: {
                ...prevState.currentCmsPhrase,
                phraseContent: phraseContent
            }
        }));
    }

    async updatePhrase() {
        await this.setState(prevState => ({
            currentCmsPhrase: {
                ...prevState.currentCmsPhrase,
                languageId: this.state.languageId
            }
        }));

        console.log(this.state.currentCmsPhrase);

        CmsPhraseDataService.update(
            this.state.currentCmsPhrase.phraseId,
            this.state.currentCmsPhrase)
            .then(response => {
                this.setState({
                    message: "The phrase was updated successfully!"
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

  render() {
    const { pages, currentCmsPhrase } = this.state;

    return (
      <div>
        <h1 id="tabelLabel" >Content Management</h1>
        <p>This tool allows for you to edit selected texts on your website.</p>

            <div className="row">
                <div className="col-md-5">
                    <h4>Pages/Sections</h4>
                    Please select a phrase to work with from the list below.
                </div>
                <div className="col-md-3">
                    <label htmlFor="pageId"><strong>Language</strong></label>
                    <select
                        className="form-control"
                        id="languageId"
                        value={this.state.languageId}
                        onChange={this.onChangeLanguage}>
                        <option value="0">Select Language</option>
                        {this.state.languages &&
                            this.state.languages.map((languageEntity, index) => (
                                <option key={languageEntity.languageId} value={languageEntity.languageId}>{languageEntity.languageName}</option>
                            ))}
                    </select>
                </div>
                <div className="col-md-4">
                    Pages: <Link to={"/cmspages/add"}>Create Page</Link> | <Link to={"/cmspages/list"}>List</Link><br />
                    Phrases: <Link to={"/cmsphrases/add"}>Create Phrase</Link> | <Link to={"/cmsphrases/list"}>List</Link><br />
                    Languages: <Link to={"/languages/add"}>Create Language</Link> | <Link to={"/languages/list"}>List</Link>                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <hr />
                </div>
            </div>
            
            <div className="list row">
                <div className="col-md-4">
                    <ul className="list-group">
                        {pages &&
                            pages.map((cmspage, index) => (
                            <div className="list-group-item" key={index}>
                                <strong>{cmspage.pageName}</strong><br />
                                <ul>
                                    {cmspage.cms_Phrases.map((cmsPhrase, subIndex) => <li key={cmsPhrase.phraseName}><span className="lnkPhrase" onClick={() => this.setActiveCmsPhrase(cmsPhrase.phraseId)}>{cmsPhrase.phraseName}</span></li>)}
                                </ul>
                            </div>
                        ))}
                    </ul>
                </div>
                <div className="col-md-8">
                    {currentCmsPhrase ? (
                        <div>
                            <h4>Selected Phrase</h4>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentCmsPhrase.phraseName}
                            </div>
                            <div>
                                <label>
                                    <strong>Description:</strong>
                                </label>{" "}
                                {currentCmsPhrase.phraseDescription}
                            </div>
                            <div>
                                <label htmlFor="description"><strong>Content</strong></label>
                                <textarea
                                    className="form-control"
                                    id="phraseContent"
                                    value={currentCmsPhrase.phraseContent}
                                    onChange={this.onChangePhraseContent}
                                />
                            </div>

                            <button
                                type="submit"
                                className="badge badge-success"
                                onClick={this.updatePhrase}
                            >
                                Update
                            </button>
                            <p>{this.state.message}</p>
                        </div>
                    ) : (
                        <div>
                            <br />
                        </div>
                    )}
                </div>
            </div>
      </div>
    );
  }
}
