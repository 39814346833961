import React, { Component } from "react";
import cmsphraseDataService from "../../services/cmsphrase.service";
import cmsPageDataService from "../../services/cmspage.service";

export class CMSPhrase extends Component {
    constructor(props) {
        super(props);
        this.onChangePhraseName = this.onChangePhraseName.bind(this);
        this.onChangePhraseKeyword = this.onChangePhraseKeyword.bind(this);
        this.onChangePhraseContent = this.onChangePhraseContent.bind(this);
        this.onChangePhraseDescription = this.onChangePhraseDescription.bind(this);
        this.onChangePageId = this.onChangePageId.bind(this);
        this.getPhrase = this.getPhrase.bind(this);
        this.updatePhrase = this.updatePhrase.bind(this);
        this.deletePhrase = this.deletePhrase.bind(this);

        this.state = {
            currentPhrase: {
                phraseId: null,
                phraseName: "",
                phraseKeyword: "",
                phraseContent: "",
                phraseDescription: "",
                cms_page_id: "",
                languageId: "",
            },
            message: ""
        };
    }

    componentDidMount() {
        this.getPhrase(this.props.match.params.id);
    }

    onChangePhraseName(e) {
        const phraseName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPhrase: {
                    ...prevState.currentPhrase,
                    phraseName: phraseName
                }
            };
        });
    }

    onChangePhraseKeyword(e) {
        const phraseKeyword = e.target.value;

        this.setState(function (prevState) {
            return {
                currentPhrase: {
                    ...prevState.currentPhrase,
                    phraseKeyword: phraseKeyword
                }
            };
        });
    }

    onChangePhraseContent(e) {
        const phraseContent = e.target.value;

        this.setState(prevState => ({
            currentPhrase: {
                ...prevState.currentPhrase,
                phraseContent: phraseContent
            }
        }));
    }

    onChangePhraseDescription(e) {
        const phraseDescription = e.target.value;

        this.setState(prevState => ({
            currentPhrase: {
                ...prevState.currentPhrase,
                phraseDescription: phraseDescription
            }
        }));
    }

    onChangePageId(e) {
        const pageId = e.target.value;
        this.setState(prevState => ({
            currentPhrase: {
                ...prevState.currentPhrase,
                pageId: pageId
            }
        }));
    }

    getPhrase(phraseId) {
        console.log(phraseId)
        cmsphraseDataService.get(phraseId)
            .then(response => {
                this.setState({
                    currentPhrase: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        cmsPageDataService.getAll()
            .then(response => {
                this.setState({
                    pages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updatePhrase() {
        cmsphraseDataService.update(
            this.state.currentPhrase.phraseId,
            this.state.currentPhrase)
            .then(response => {
                this.setState({
                    message: "The phrase was updated successfully!"
                });
                alert("Phrase updated successfully!");
                this.props.history.push('/cms');
            })
            .catch(e => {
                console.log(e);
            });
    }

    deletePhrase() {
        cmsphraseDataService.delete(this.state.currentPhrase.phraseId)
            .then(response => {
                console.log(response.data);
                alert("Phrase removed successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const currentPhrase = this.state.currentPhrase ? this.state.currentPhrase : null;

        return (
            <div>
                {currentPhrase ? (
                    <div className="edit-form">
                        <h4>Phrase</h4>
                        <form>
                            <div className="form-group">
                                <label htmlFor="title">Phrase Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phraseName"
                                    value={currentPhrase.phraseName}
                                    onChange={this.onChangePhraseName}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="title">Phrase Keyword</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phraseKeyword"
                                    value={currentPhrase.phraseKeyword}
                                    onChange={this.onChangePhraseKeyword}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    className="form-control"
                                    id="phraseDescription"
                                    value={currentPhrase.phraseDescription}
                                    onChange={this.onChangePhraseDescription}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Content</label>
                                <textarea
                                    className="form-control"
                                    id="phraseContent"
                                    value={currentPhrase.phraseContent}
                                    onChange={this.onChangePhraseContent}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="pageId">Content</label>
                                <select
                                    className="form-control"
                                    id="pageId"
                                    value={currentPhrase.pageId}
                                    onChange={this.onChangePageId}>
                                    {this.state.pages &&
                                        this.state.pages.map((pageEntity, index) => (
                                            <option key={pageEntity.pageId} value={pageEntity.pageId}>{pageEntity.pageName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="pageId">Language</label><br/>
                                &nbsp;{this.state.currentPhrase.language_Id}
                            </div>
                        </form>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.deletePhrase}
                        >
                            Delete
                        </button>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={this.updatePhrase}
                        >
                            Update
                        </button>
                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a phrase...</p>
                    </div>
                )}
            </div>
        );
    }
}