import React, { Component } from "react";
import MenuCategoryTypeService from "./../../services/menucategorytype.service";
import { Link } from "react-router-dom";

export class MenuCategoryTypeList extends Component {
    constructor(props) {
        super(props);
        this.retrieveCategoryTypes = this.retrieveCategoryTypes.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.setActiveCategoryType = this.setActiveCategoryType.bind(this);

        this.state = {
            tutorials: [],
            currentTutorial: null,
            currentIndex: -1
        };
    }

    componentDidMount() {
        this.retrieveCategoryTypes();
    }

    retrieveCategoryTypes() {
        MenuCategoryTypeService.getAll()
            .then(response => {
                this.setState({
                    tutorials: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshList() {
        this.retrieveCategoryTypes();
        this.setState({
            currentTutorial: null,
            currentIndex: -1
        });
    }

    setActiveCategoryType(tutorial, index) {
        this.setState({
            currentTutorial: tutorial,
            currentIndex: index
        });
    }

    render() {
        const { tutorials, currentTutorial, currentIndex } = this.state;

        return (
            <div className="list row">
                <div className="col-md-6">
                    <h4>Type</h4>
                    <p>Please select a category type from the list below</p>

                    <ul className="list-group">
                        {tutorials &&
                            tutorials.map((tutorial, index) => (
                                <li
                                    className={
                                        "list-group-item " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => this.setActiveCategoryType(tutorial, index)}
                                    key={index}
                                >
                                    {tutorial.name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="col-md-6">
                    {currentTutorial ? (
                        <div>
                            <h4>Category Type</h4>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentTutorial.name}
                            </div>

                            <Link
                                to={"/menucategorytypes/" + currentTutorial.menuCategoryTypeId}
                                className="badge badge-warning"
                            >
                                Edit
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <br />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}