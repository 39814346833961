import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { About } from './components/About';
import { Dashboard } from './components/Dashboard';
import { Reservations } from './components/Reservations';
import { Menu } from './components/Menu';
import { CMS } from './components/CMS';
import { Language } from './components/language/language';
import { LanguageList } from './components/language/language_list';
import { LanguageAdd } from './components/language/language_add';
import { CMSPage } from './components/cmspage/cmspage';
import { CMSPageList } from './components/cmspage/cmspage_list';
import { CMSPageAdd } from './components/cmspage/cmspage_add';
import { CMSPhrase } from './components/cmsphrase/cmsphrase';
import { CMSPhraseList } from './components/cmsphrase/cmsphrase_list';
import { CMSPhraseAdd } from './components/cmsphrase/cmsphrase_add';
import { MenuCategory } from './components/menucategory/menucategory';
import { MenuCategoryList } from './components/menucategory/menucategory_list';
import { MenuCategoryAdd } from './components/menucategory/menucategory_add';
import { MenuCategoryType } from './components/menucategorytype/menucategorytype';
import { MenuCategoryTypeList } from './components/menucategorytype/menucategorytype_list';
import { MenuCategoryTypeAdd } from './components/menucategorytype/menucategorytype_add';
import { MenuItem } from './components/menuitem/menuitem';
import { MenuItemList } from './components/menuitem/menuitem_list';
import { MenuItemAdd } from './components/menuitem/menuitem_add';
import { MenuItemClassification } from './components/menuitemclassification/menuitemclassification';
import { MenuItemClassificationList } from './components/menuitemclassification/menuitemclassification_list';
import { MenuItemClassificationAdd } from './components/menuitemclassification/menuitemclassification_add';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path={['/'], ['home']} component={Home} />
            <Route path='/about' component={About} />

            <AuthorizeRoute path='/dashboard' component={Dashboard} />
            <AuthorizeRoute path='/cms' component={CMS} />
            <AuthorizeRoute path='/menu' component={Menu} />
            <AuthorizeRoute path='/reservations' component={Reservations} />

            <AuthorizeRoute exact path="/cmspage/list" component={CMSPageList} />
            <AuthorizeRoute exact path="/cmspage/add" component={CMSPageAdd} />
            <AuthorizeRoute path="/cmspages/:id" component={CMSPage} />

            <AuthorizeRoute exact path="/language/list" component={LanguageList} />
            <AuthorizeRoute exact path="/language/add" component={LanguageAdd} />
            <AuthorizeRoute path="/languages/:id" component={Language} />

            <AuthorizeRoute exact path="/cmsphrase/list" component={CMSPhraseList} />
            <AuthorizeRoute exact path="/cmsphrase/add" component={CMSPhraseAdd} />
            <AuthorizeRoute path="/cmsphrases/:id" component={CMSPhrase} />

            <AuthorizeRoute exact path="/menucategorytype/list" component={MenuCategoryTypeList} />
            <AuthorizeRoute exact path="/menucategorytype/add" component={MenuCategoryTypeAdd} />
            <AuthorizeRoute path="/menucategorytypes/:id" component={MenuCategoryType} />

            <AuthorizeRoute exact path="/menucategory/list" component={MenuCategoryList} />
            <AuthorizeRoute exact path="/menucategory/add" component={MenuCategoryAdd} />
            <AuthorizeRoute path="/menucategories/:id" component={MenuCategory} />

            <AuthorizeRoute exact path="/menuitem/list" component={MenuItemList} />
            <AuthorizeRoute exact path="/menuitem/add" component={MenuItemAdd} />
            <AuthorizeRoute path="/menuitems/:id" component={MenuItem} />

            <AuthorizeRoute exact path="/menuitemclassification/list" component={MenuItemClassificationList} />
            <AuthorizeRoute exact path="/menuitemclassification/add" component={MenuItemClassificationAdd} />
            <AuthorizeRoute path="/menuitemclassification/:id" component={MenuItemClassification} />

            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
