import http from "../base/httpcomponent";

class CmsPhraseDataService {
    getAll() {
        return http.get("/cmsphrase/getall");
    }

    get(id) {
        return http.get(`/cmsphrase/${id}`);
    }

    create(data) {
        return http.post("/cmsphrase/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/cmsphrase/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/cmsphrase/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/cmsphrase`);
    }
}

export default new CmsPhraseDataService();