import React, { Component } from "react";
import cmspageDataService from "../../services/cmspage.service";

export class MenuCategory extends Component {
    constructor(props) {
        super(props);
        this.onChangePageName = this.onChangePageName.bind(this);
        this.onChangePageDescription = this.onChangePageDescription.bind(this);
        this.getTutorial = this.getTutorial.bind(this);
        this.updatePublished = this.updatePublished.bind(this);
        this.updateTutorial = this.updateTutorial.bind(this);
        this.deleteTutorial = this.deleteTutorial.bind(this);

        this.state = {
            currentTutorial: {
                pageId: null,
                pageName: "",
                pageDescription: ""
            },
            message: ""
        };
    }

    componentDidMount() {
        this.getTutorial(this.props.match.params.id);
    }

    onChangePageName(e) {
        const pageName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentTutorial: {
                    ...prevState.currentTutorial,
                    pageName: pageName
                }
            };
        });
    }

    onChangePageDescription(e) {
        const pageDescription = e.target.value;

        this.setState(prevState => ({
            currentTutorial: {
                ...prevState.currentTutorial,
                pageDescription: pageDescription
            }
        }));
    }

    getTutorial(id) {
        cmspageDataService.get(id)
            .then(response => {
                this.setState({
                    currentTutorial: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updatePublished(status) {
        var data = {
            id: this.state.currentTutorial.id,
            title: this.state.currentTutorial.title,
            description: this.state.currentTutorial.description,
            published: status
        };

        cmspageDataService.update(this.state.currentTutorial.id, data)
            .then(response => {
                this.setState(prevState => ({
                    currentTutorial: {
                        ...prevState.currentTutorial,
                        published: status
                    }
                }));
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updateTutorial() {
        cmspageDataService.update(
            this.state.currentTutorial.pageId,
            this.state.currentTutorial
        )
            .then(response => {
                console.log(response.data);
                this.setState({
                    message: "The page was updated successfully!"
                });
                alert("Page updated successfully!");
                this.props.history.push('/cms');
            })
            .catch(e => {
                console.log(e);
            });
    }

    deleteTutorial() {
        cmspageDataService.delete(this.state.currentTutorial.pageId)
            .then(response => {
                console.log(response.data);
                alert("Page removed successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { currentTutorial } = this.state;

        return (
            <div>
                {currentTutorial ? (
                    <div className="edit-form">
                        <h4>Page</h4>
                        <form>
                            <div className="form-group">
                                <label htmlFor="title">Page Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={currentTutorial.pageName}
                                    onChange={this.onChangePageName}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    value={currentTutorial.pageDescription}
                                    onChange={this.onChangePageDescription}
                                />
                            </div>
                        </form>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.deleteTutorial}
                        >
                            Delete
                        </button>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={this.updateTutorial}
                        >
                            Update
                        </button>
                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a page...</p>
                    </div>
                )}
            </div>
        );
    }
}