import http from "../base/httpcomponent";

class MenuCategoryDataService {
    getAll() {
        return http.get("/menucategory/getall");
    }

    get(id) {
        return http.get(`/menucategory/${id}`);
    }

    create(data) {
        return http.post("/menucategory/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/menucategory/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/menucategory/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/menucategory`);
    }
}

export default new MenuCategoryDataService();