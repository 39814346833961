import React, { Component } from "react";
import MenuCategoryService from "./../../services/menucategory.service";
import MenuCategoryTypeService from "./../../services/menucategorytype.service";
import LanguageDataService from "./../../services/language.service";
import { Link } from "react-router-dom";

export class MenuCategoryAdd extends Component {
    constructor(props) {
        super(props);
        
        this.onChangecategoryName = this.onChangecategoryName.bind(this);
        this.onChangecategoryTitle = this.onChangecategoryTitle.bind(this);
        this.onChangecategoryDescription = this.onChangecategoryDescription.bind(this);
        this.onChangecategoryTypeId = this.onChangecategoryTypeId.bind(this);        
        this.saveMenuCategory = this.saveMenuCategory.bind(this);
        this.newMenuCategory = this.newMenuCategory.bind(this);
        this.loadCategoryData = this.loadCategoryData.bind(this);
        this.onChangeLanguageId = this.onChangeLanguageId.bind(this);

        this.state = {
            categoryId: null,
            categoryName: "",
            categoryTitle: "",
            categoryDescription: "",
            categoryType: "",
            languageId: "",
            languages: [],
            types: [],
            submitted: false
        };
    }

    componentDidMount() {
        this.loadCategoryData();
    }

    loadCategoryData() {

        MenuCategoryTypeService.getAll()
            .then(response => {
                this.setState({
                    types: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        LanguageDataService.getAll()
            .then(response => {
                this.setState({
                    languages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }

    onChangeLanguageId(e) {
        this.setState({
            languageId: e.target.value
        });
    }

    onChangecategoryTitle(e) {
        this.setState({
            categoryTitle: e.target.value
        });
    }

    onChangecategoryName(e) {
        this.setState({
            categoryName: e.target.value
        });
    }

    onChangecategoryDescription(e) {
        this.setState({
            categoryDescription: e.target.value
        });
    }

    onChangecategoryTypeId(e) {
        this.setState({
            categoryTypeId: e.target.value
        });
    }

    saveMenuCategory() {
        var data = {
            CategoryName: this.state.categoryName,
            CategoryTypeId: this.state.categoryTypeId,
            CategoryTitle: this.state.categoryTitle,
            CategoryDescription: this.state.categoryDescription,
            Language_Id: this.state.languageId,
        };

        console.log("Adding...")
        console.log(data);

        MenuCategoryService.create(data)
            .then(response => {
                this.setState({
                    categoryId: response.data.id,
                    categoryName: response.data.categoryName,
                    categoryTitle: response.data.categoryTitle,
                    categoryDescription: response.data.categoryDescription,
                    categoryType: response.data.categoryType,
                    submitted: true
                });
                console.log(response.data);
                alert("category created successfully!")
                this.props.history.push('/menu')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newMenuCategory() {
        this.setState({
            categoryId: null,
            categoryName: "",
            categoryTitle: "",
            categoryDescription: "",
            categoryType: "",
            submitted: false
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create category</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new category successfully!</h4>
                        <Link to={"/menu"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                            <div className="form-group">
                                <label htmlFor="title">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    required
                                    value={this.state.categoryName}
                                    onChange={this.onChangecategoryName}
                                    name="name"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    required
                                    value={this.state.categoryTitle}
                                    onChange={this.onChangecategoryTitle}
                                    name="title"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    required
                                    value={this.state.categoryDescription}
                                    onChange={this.onChangecategoryDescription}
                                    name="description"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="categoryId">Type</label>
                                <select
                                    className="form-control"
                                    id="categoryId"
                                    value={this.state.categoryTypeId}
                                    onChange={this.onChangecategoryTypeId}>
                                    <option value="0">Select category type</option>
                                    {this.state.types &&
                                        this.state.types.map((categoryTypeEntity, index) => (
                                            <option key={categoryTypeEntity.Menu_Items} value={categoryTypeEntity.menuCategoryTypeId}>{categoryTypeEntity.name}</option>
                                        ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="categoryId">Language</label>
                                <select
                                    className="form-control"
                                    id="categoryId"
                                    value={this.state.languageId}
                                    onChange={this.onChangeLanguageId}>
                                    <option value="0">Select language</option>
                                    {this.state.languages &&
                                        this.state.languages.map((languageEntity, index) => (
                                            <option key={languageEntity.languageId} value={languageEntity.languageId}>{languageEntity.languageName}</option>
                                        ))}
                                </select>
                            </div>

                            <button onClick={() =>this.saveMenuCategory()} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
