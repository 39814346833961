import React, { Component } from 'react';

export class Dashboard extends Component {
    static displayName = Dashboard.name;

  render () {
    return (
      <div>
        <h1>eCateringWeb Administration</h1>
        <p>Welcome to eCateringWeb Administration! This </p>
      </div>
    );
  }
}
