import React, { Component } from "react";
import LanguageDataService from "./../../services/language.service";
import { Link } from "react-router-dom";

export class LanguageAdd extends Component {
    constructor(props) {
        super(props);
        this.onChangeLanguageName = this.onChangeLanguageName.bind(this);
        this.saveLanguage = this.saveLanguage.bind(this);
        this.newLanguage = this.newLanguage.bind(this);

        this.state = {
            languageId: null,
            languageName: "",
            submitted: false
        };
    }

    onChangeLanguageName(e) {
        this.setState({
            languageName: e.target.value
        });
    }

    saveLanguage() {
        var data = {
            LanguageName: this.state.languageName
        };

        console.log("Adding...")

        LanguageDataService.create(data)
            .then(response => {
                this.setState({
                    languageId: response.data.id,
                    languageName: response.data.pageName,
                    submitted: true
                });
                console.log(response.data);
                alert("Page created successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newLanguage() {
        this.setState({
            languageId: null,
            languageName: "",
            submitted: false
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create Language</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new language successfully!</h4>
                        <Link to={"/cms"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                        <div className="form-group">
                            <label htmlFor="title">Language Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                required
                                value={this.state.languageName}
                                onChange={this.onChangeLanguageName}
                                name="languageName"
                            />
                        </div>

                            <button onClick={() =>this.saveLanguage()} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
