import React, { Component } from "react";
import MenuCategoryDataService from "../../services/menucategory.service";
import MenuItemDataService from "../../services/menuitem.service";
import LanguageDataService from "../../services/language.service";
import { Link } from "react-router-dom";

export class MenuItemAdd extends Component {
    constructor(props) {
        super(props);
        this.onChangeItemName = this.onChangeItemName.bind(this);
        this.onChangeItemTitle = this.onChangeItemTitle.bind(this);
        this.onChangeItemDescription = this.onChangeItemDescription.bind(this);
        this.saveMenuItem = this.saveMenuItem.bind(this);
        this.newMenuItem = this.newMenuItem.bind(this);
        this.loadMenuData = this.loadMenuData.bind(this);
        this.onChangeCategoryId = this.onChangeCategoryId.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);

        this.state = {
            phraseId: null,
            itemName: "",
            itemTitle: "",
            itemDescription: "",
            languageId: "",
            CategoryId: "",
            categories: null,
            languages: null,
        };
    }

    componentDidMount() {
        this.loadMenuData();
    }

    loadMenuData() {

        MenuCategoryDataService.getAll()
            .then(response => {
                this.setState({
                    categories: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        LanguageDataService.getAll()
            .then(response => {
                this.setState({
                    languages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }

    onChangeItemName(e) {
        this.setState({
            itemName: e.target.value
        });
    }

    onChangeItemTitle(e) {
        this.setState({
            itemTitle: e.target.value
        });
    }

    onChangeItemDescription(e) {
        this.setState({
            itemDescription: e.target.value
        });
    }

    onChangeCategoryId(e) {
        this.setState({
            CategoryId: e.target.value
        });
    }

    onChangeLanguage(e) {
        this.setState({
            languageId: e.target.value
        });
    }

    saveMenuItem() {
        var data = {
            ItemName: this.state.itemName,
            ItemTitle: this.state.itemTitle,
            ItemDescription: this.state.itemDescription,
            CategoryId: this.state.CategoryId,
            LanguageId: this.state.languageId
        };

        console.log(data)

        MenuItemDataService.create(data)
            .then(response => {               
                alert("Menu item created successfully!")
                this.props.history.push('/menu')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newMenuItem() {
        this.setState({
            itemId: null,
            itemName: "",
            itemTitle: "",
            itemDescription: "",
            CategoryId: "",
            LanguageId: "",
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create Menu item</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new Menu item successfully!</h4>
                        <Link to={"/cms"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                        <div className="form-group">
                            <label htmlFor="title">Internal Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                required
                                value={this.state.itemName}
                                onChange={this.onChangeItemName}
                                name="title"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="title">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ItemTitle"
                                value={this.state.itemTitle}
                                onChange={this.onChangeItemTitle}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="description"
                                required
                                value={this.state.itemDescription}
                                onChange={this.onChangeItemDescription}
                                name="description"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="CategoryId">Category</label>
                            <select
                                className="form-control"
                                id="CategoryId"
                                value={this.state.categoryId}
                                onChange={this.onChangeCategoryId}>
                                    <option value="0">Select Category</option>
                                    {this.state.categories &&
                                        this.state.categories.map((categoryEntity, index) => (
                                            <option key={categoryEntity.category.categoryId} value={categoryEntity.category.categoryId}>{categoryEntity.category.categoryName}</option>
                                    ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="CategoryId">Language</label>
                            <select
                                className="form-control"
                                id="CategoryId"
                                value={this.state.languageId}
                                    onChange={this.onChangeLanguage}>
                                    <option value="0">Select Language</option>
                                {this.state.languages &&
                                        this.state.languages.map((languageEntity, index) => (
                                            <option key={languageEntity.languageId} value={languageEntity.languageId}>{languageEntity.languageName}</option>
                                    ))}
                            </select>
                        </div>

                            <button onClick={() =>this.saveMenuItem()} className="btn btn-success">
                            Create
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
