import React, { Component } from "react";
import LanguageDataService from "./../../services/language.service";

export class Language extends Component {
    constructor(props) {
        super(props);
        this.onChangeLanguageName = this.onChangeLanguageName.bind(this);
        this.getLanguage = this.getLanguage.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
        this.deleteLanguage = this.deleteLanguage.bind(this);

        this.state = {
            currentLanguage: {
                languageId: null,
                languageName: ""
            },
            message: ""
        };
    }

    componentDidMount() {
        this.getLanguage(this.props.match.params.id);
    }

    onChangeLanguageName(e) {
        const languageName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentLanguage: {
                    ...prevState.currentLanguage,
                    languageName: languageName
                }
            };
        });
    }

    getLanguage(id) {
        LanguageDataService.get(id)
            .then(response => {
                this.setState({
                    currentLanguage: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    updateLanguage() {
        LanguageDataService.update(
            this.state.currentLanguage.languageId,
            this.state.currentLanguage
        )
            .then(response => {
                console.log(response.data);
                this.setState({
                    message: "The language was updated successfully!"
                });
                alert("language updated successfully!");
                this.props.history.push('/cms');
            })
            .catch(e => {
                console.log(e);
            });
    }

    deleteLanguage() {
        LanguageDataService.delete(this.state.currentLanguage.languageId)
            .then(response => {
                console.log(response.data);
                alert("Page removed successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { currentLanguage } = this.state;

        return (
            <div>
                {currentLanguage ? (
                    <div className="edit-form">
                        <h4>Page</h4>
                        <form>
                            <div className="form-group">
                                <label htmlFor="title">Language Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    value={currentLanguage.languageName}
                                    onChange={this.onChangeLanguageName}
                                />
                            </div>
                        </form>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.deleteLanguage}
                        >
                            Delete
                        </button>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={this.updateLanguage}
                        >
                            Update
                        </button>
                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Please click on a language...</p>
                    </div>
                )}
            </div>
        );
    }
}