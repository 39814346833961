import React, { Component } from "react";
import CmsPhraseDataService from "../../services/cmsphrase.service";
import { Link } from "react-router-dom";

export class CMSPhraseList extends Component {
    constructor(props) {
        super(props);
        this.retrievePhrases = this.retrievePhrases.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.setActivePhrase = this.setActivePhrase.bind(this);

        this.state = {
            tutorials: [],
            currentTutorial: null,
            currentIndex: -1,
            searchTitle: ""
        };
    }

    componentDidMount() {
        this.retrievePhrases();
    }

    retrievePhrases() {
        CmsPhraseDataService.getAll()
            .then(response => {
                this.setState({
                    tutorials: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshList() {
        this.retrievePhrases();
        this.setState({
            currentTutorial: null,
            currentIndex: -1
        });
    }

    setActivePhrase(tutorial, index) {
        this.setState({
            currentTutorial: tutorial,
            currentIndex: index
        });
    }

    render() {
        const { tutorials, currentTutorial, currentIndex } = this.state;

        return (
            <div className="list row">
                <div className="col-md-6">
                    <h4>Phrases</h4>
                    <p>Please select a phrase from the list below</p>

                    <ul className="list-group">
                        {tutorials &&
                            tutorials.map((tutorial, index) => (
                                <li
                                    className={
                                        "list-group-item " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => this.setActivePhrase(tutorial, index)}
                                    key={index}
                                >
                                    {tutorial.phraseName}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="col-md-6">
                    {currentTutorial ? (
                        <div>
                            <h4>Phrase</h4>
                            <div>
                                <label>
                                    <strong>Phrase Name:</strong>
                                </label>{" "}
                                {currentTutorial.phraseName}
                            </div>
                            <div>
                                <label>
                                    <strong>Page:</strong>
                                </label>{" "}
                                {currentTutorial.cms_Page_Id}
                            </div>

                            <Link
                                to={"/cmsphrase/" + currentTutorial.phraseId}
                                className="badge badge-warning"
                            >
                                Edit
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <br />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}