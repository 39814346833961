import axios from "axios";
import authService from "./../components/api-authorization/AuthorizeService";

const API_URL = "https://localhost:44388/api";
const token = (async () => { await authService.getAccessToken(); })();

export default axios.create({

    baseURL: API_URL,
    headers: {
        "Content-type": "application/json"
    }
});