import React, { Component } from 'react';
import { Link } from "react-router-dom";
import MenuCategoryService from "./../services/menucategory.service";
import MenuItemService from "./../services/menuitem.service";
import MenuItemClassificationService from "./../services/menuitemclassification.service";
import LanguageDataService from "./../services/language.service";

const defaultImageSrc = '/img/image_placeholder.png'

export class Menu extends Component {
    static displayName = Menu.name;

      constructor(props) {
          super(props);

          this.onChangeMenuItemName = this.onChangeMenuItemName.bind(this);
          this.onChangeMenuItemTitle = this.onChangeMenuItemTitle.bind(this);
          this.onChangeMenuItemDescription = this.onChangeMenuItemDescription.bind(this);
          this.updateMenuItem = this.updateMenuItem.bind(this);
          this.onChangeLanguage = this.onChangeLanguage.bind(this);
          this.setActiveMenuItem = this.setActiveMenuItem.bind(this);
          this.setShowNewMenuItem = this.setShowNewMenuItem.bind(this);
          this.createMenuItem = this.createMenuItem.bind(this);
          this.onChangeMenuItemPrice = this.onChangeMenuItemPrice.bind(this);
          this.showPreview = this.showPreview.bind(this);

          this.state = {
              cmsdata: [],
              categories: [],
              currentCategory: null,
              currentMenuItem: null,
              itemName: "",
              itemTitle: "",
              itemPrice: 0,
              itemDescription: "",
              loading: true,
              currentIndex: 0,
              languageId: "ca6acbc9-0393-4703-c708-08d98e1b9f8f",
              languages: null,
              showNewMenuItem: false,
              imageName: '',
              imageSrc: '/img/image_placeholder.png',
              imageFile: null
          };
      }

    componentDidMount() {
        this.retrieveCategories();
        LanguageDataService.getAll()
            .then(response => {
                this.setState({
                    languages: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    retrieveCategories() {
        MenuCategoryService.getAll()
            .then(response => {
                this.setState({
                    categories: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    refreshList() {
        this.retrieveCategories();
        this.setState({
            currentCategory: null,
            currentMenuItem: null,
            currentIndex: -1
        });
    }

    setShowNewMenuItem(curCategory) {
        this.setState({
            itemName: '',
            itemTitle: '',
            itemDescription: '',
            CategoryId: curCategory,
            showNewMenuItem: true,
            currentMenuItem: null
        });
    }    

    setActiveCmsPage(tutorial, index) {
        this.setState({
            currentCategory: tutorial,
            currentIndex: index
        });
    }

    onChangeMenuItemName(e) {
        this.setState({ itemName: e.target.value });
    }

    onChangeMenuItemTitle(e) {
        this.setState({ itemTitle: e.target.value });
    }

    onChangeMenuItemPrice(e) {
        this.setState({ itemPrice: e.target.value });
    }

    onChangeMenuItemDescription(e) {
        this.setState({ itemDescription: e.target.value });
    }

    showPreview(e) {
        if (e.target.files && e.target.files[0]) {
            let imageFiler = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                this.setState({
                    imageFile: imageFiler,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFiler)
        }
        else {
            this.setState({
                imageFile: null,
                imageSrc: defaultImageSrc
            })
        }
    }

    async setActiveMenuItem(menuItemId) {

        await this.setState(prevState => ({
            currentMenuItem: null,
            showNewMenuItem: false,
        }));

        MenuItemService.get(menuItemId, this.state.languageId)
            .then(response => {
                this.setState({
                    currentMenuItem: response.data
                });
                console.log(response.data);

                for (var i = 0; i < response.data.translationList.length; i++) {
                    if (response.data.translationList[i].language_Id === this.state.languageId) {
                        this.setState({
                            itemName: response.data.itemName,
                            itemPrice: response.data.itemPrice,
                            itemTitle: response.data.translationList[i].itemTitle,
                            itemDescription: response.data.translationList[i].itemDescription,
                            CategoryId: response.data.CategoryId,
                            imageSrc: response.data.imageSrc
                        });
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    async onChangeLanguage(e) {
        await this.setState({
            languageId: e.target.value
        });
        console.log(this.state.languageId)
        this.refreshList();
    }

    async updateMenuItem() {

        const formData = new FormData()
        formData.append('ItemId', this.state.currentMenuItem.itemId)
        formData.append('ItemName', this.state.itemName)
        formData.append('ItemTitle', this.state.itemTitle)
        formData.append('ItemPrice', this.state.itemPrice)
        formData.append('ImageFile', this.state.imageFile)
        formData.append('ItemDescription', this.state.itemDescription)
        formData.append('CategoryId', this.state.CategoryId)
        formData.append('LanguageId', this.state.languageId)

        console.log(formData);

        MenuItemService.update(
            this.state.currentMenuItem.itemId,
            formData)
            .then(response => {
                this.setState({
                    message: "The menu item was updated successfully!",
                    showNewMenuItem: false,
                    currentMenuItem: null,
                    itemName: '',
                    itemTitle: '',
                    itemDescription: '',
                    itemPrice: '',
                });
                this.refreshList();
            })
            .catch(e => {
                console.log(e);
            });
    }
    
    async removeMenuItem(itemId) {

        MenuItemService.delete(itemId)
            .then(response => {
                this.setState({
                    message: "The menu item was deleted successfully!",
                    showNewMenuItem: false
                });
                this.refreshList();
            })
            .catch(e => {
                console.log(e);
            });

    }

    async createMenuItem() {

        const createFormData = new FormData()
        createFormData.append('ItemName', this.state.itemName)
        createFormData.append('ItemTitle', this.state.itemTitle)
        createFormData.append('ItemPrice', this.state.itemPrice)
        createFormData.append('ImageFile', this.state.imageFile)
        createFormData.append('ItemDescription', this.state.itemDescription)
        createFormData.append('CategoryId', this.state.CategoryId)
        createFormData.append('LanguageId', this.state.languageId)

        console.log(createFormData);

        MenuItemService.create(createFormData)
            .then(response => {
                this.setState({
                    message: "The menu item was created successfully!",
                    showNewMenuItem: false
                });
                this.refreshList();
            })
            .catch(e => {
                console.log(e);
            });

    }

  render() {
    const { categories, currentMenuItem } = this.state;

    return (
      <div>
            <div className="row">
                <div className="col-md-5">
                    <h1 id="tabelLabel" >Menu Management</h1>
                    <p>This tool allows for you to edit the menu on your website.</p>
                </div>
                <div className="col-md-3">
                    <label htmlFor="pageId"><strong>Language</strong></label>
                    <select
                        className="form-control"
                        id="languageId"
                        value={this.state.languageId}
                        onChange={this.onChangeLanguage}>
                        <option value="0">Select Language</option>
                        {this.state.languages &&
                            this.state.languages.map((languageEntity, index) => (
                                <option key={languageEntity.languageId} value={languageEntity.languageId}>{languageEntity.languageName}</option>
                            ))}
                    </select>
                </div>
                <div className="col-md-4">
                    Categories: <Link to={"/menucategory/add"}>Add</Link> | <Link to={"/menucategory/list"}>List</Link><br />
                    Category Types: <Link to={"/menucategorytype/add"}>Add</Link> | <Link to={"/menucategorytype/list"}>List</Link><br />
                    Menu Item Classifications: <Link to={"/menuitemclassification/add"}>Add</Link> | <Link to={"/menuitemclassification/list"}>List</Link><br />
                    Languages: <Link to={"/languages/add"}>Add</Link> | <Link to={"/languages/list"}>List</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <hr />
                </div>
            </div>
            
            <div className="list row">
                <div className="col-md-5">
                    <ul className="list-group">
                        {categories &&
                            categories.map((categoryValue, index) => (
                            <div className="list-group-item" key={index}>
                                <strong>{categoryValue.category.categoryName}</strong> <span className="lnkPhrase" onClick={() => this.setShowNewMenuItem(categoryValue.category.categoryId)}>(+)</span><br />
                                <ul>
                                    {categoryValue.menuItems && categoryValue.menuItems.map((tmpMenuItem, subIndex) => <li key={tmpMenuItem.itemId}><span className="lnkPhrase" onClick={() => this.setActiveMenuItem(tmpMenuItem.itemId)}>{tmpMenuItem.itemName}</span> <span className="lnkPhrase" onClick={() => this.removeMenuItem(tmpMenuItem.itemId)}>(X)</span></li>)}
                                </ul>
                            </div>
                        ))}
                    </ul>
                </div>
                <div className="col-md-7">
                    {this.state.showNewMenuItem ? (
                        <div>
                            <h4>Create Menu Item</h4>
                            <div>
                                <label>
                                    <strong>Internal Name:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemName"
                                    value={this.state.itemName}
                                    onChange={this.onChangeMenuItemName}
                                />
                            </div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemTitle"
                                    value={this.state.itemTitle}
                                    onChange={this.onChangeMenuItemTitle}
                                />
                            </div>
                            <div>
                                <label>
                                    <strong>Description:</strong>
                                </label>{" "}
                                <textarea
                                    className="form-control"
                                    id="itemDescription"
                                    value={this.state.itemDescription}
                                    onChange={this.onChangeMenuItemDescription}
                                />
                                { }
                            </div>
                            <div>
                            <label>
                                <strong>Price:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemTitle"
                                    value={this.state.itemPrice}
                                    onChange={this.onChangeMenuItemPrice}
                                    />
                                    { }
                            </div>
                            <div>
                                <label>
                                <strong>Image:</strong>
                                </label>{" "}
                                <input type="file"
                                    accept="image/*"
                                    className="form-control"
                                    onChange={this.showPreview}
                                    id="image-create-uploader" />
                                    { }
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn-success right"
                                    onClick={this.createMenuItem}
                                >
                                Create Menu Item
                                </button>
                                <p>{this.state.message}</p>
                            </div>
                        </div>
                    ): (<div></div>)}
                    {currentMenuItem ? (
                        <div>
                            <h4>Selected Menu Item</h4>
                            <div>
                                <label>
                                    <strong>Internal Name:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemName"
                                    value={this.state.itemName}
                                    onChange={this.onChangeMenuItemName}
                                />
                            </div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemTitle"
                                    value={this.state.itemTitle}
                                    onChange={this.onChangeMenuItemTitle}
                                />
                            </div>
                            <div>
                                <label>
                                    <strong>Description:</strong>
                                </label>{" "}
                                <textarea
                                    className="form-control"
                                    id="itemDescription"
                                    value={this.state.itemDescription}
                                    onChange={this.onChangeMenuItemDescription}
                                />
                                {}
                            </div>
                            <div>
                                <label>
                                    <strong>Price:</strong>
                                </label>{" "}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemTitle"
                                    value={this.state.itemPrice}
                                    onChange={this.onChangeMenuItemPrice}
                                />

                                { }
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-md-8"><label><strong>Image:</strong></label>{" "}<input type="file"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={this.showPreview}
                                        id="image-update-uploader" /></div>
                                    <div className="col-md-4">
                                        <img src={this.state.imageSrc} className="menuItemImage rounded-circle" />
                                    </div>
                                </div>
                                
                                    
                                { }
                                
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="btn-success"
                                    onClick={this.updateMenuItem}
                                >
                                    Update Menu Item
                                </button>
                                <p>{this.state.message}</p>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
      </div>
    );
  }
}
