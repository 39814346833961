import http from "../base/httpcomponent";

class cmspageDataService {
    getAll() {
        return http.get("/cmspage/getall");
    }

    get(id) {
        return http.get(`/cmspage/${id}`);
    }

    create(data) {
        return http.post("/cmspage/add", JSON.stringify(data));
    }

    update(id, data) {
        return http.put(`/cmspage/update/${id}`, data);
    }

    delete(id) {
        return http.delete(`/cmspage/delete/${id}`);
    }

    deleteAll() {
        return http.delete(`/cmspage`);
    }
}

export default new cmspageDataService();