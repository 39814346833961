import React, { Component } from "react";
import MenuCategoryTypeDataService from "./../../services/menucategorytype.service";
import { Link } from "react-router-dom";

export class MenuCategoryTypeAdd extends Component {
    constructor(props) {
        super(props);
        this.onChangecategoryTypeName = this.onChangecategoryTypeName.bind(this);
        this.saveMenuCategoryType = this.saveMenuCategoryType.bind(this);
        this.newMenuCategoryType = this.newMenuCategoryType.bind(this);

        this.state = {
            categoryId: null,
            categoryTypeName: "",
            submitted: false
        };
    }

    onChangecategoryTypeName(e) {
        this.setState({
            categoryTypeName: e.target.value
        });
    }

    saveMenuCategoryType() {
        var data = {
            categoryTypeName: this.state.categoryTypeName,
        };

        console.log("Adding...")

        MenuCategoryTypeDataService.create(data)
            .then(response => {
                this.setState({
                    categoryId: response.data.id,
                    categoryTypeName: response.data.categoryTypeName,
                    submitted: true
                });
                console.log(response.data);
                alert("category created successfully!")
                this.props.history.push('/menu')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newMenuCategoryType() {
        this.setState({
            categoryId: null,
            categoryTypeName: "",
            submitted: false
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create category type</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new category type successfully!</h4>
                        <Link to={"/menu"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                            <div className="form-group">
                                <label htmlFor="title">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    required
                                    value={this.state.categoryTypeName}
                                    onChange={this.onChangecategoryTypeName}
                                    name="name"
                                />
                            </div>

                            <button onClick={() =>this.saveMenuCategoryType()} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
