import React, { Component } from "react";
import cmspageService from "./../../services/cmspage.service";
import { Link } from "react-router-dom";

export class CMSPageAdd extends Component {
    constructor(props) {
        super(props);
        this.onChangePageName = this.onChangePageName.bind(this);
        this.onChangePageDescription = this.onChangePageDescription.bind(this);
        this.saveCmsPage = this.saveCmsPage.bind(this);
        this.newCmsPage = this.newCmsPage.bind(this);

        this.state = {
            pageId: null,
            pageName: "",
            pageDescription: "",
            submitted: false
        };
    }

    onChangePageName(e) {
        this.setState({
            pageName: e.target.value
        });
    }

    onChangePageDescription(e) {
        this.setState({
            pageDescription: e.target.value
        });
    }

    saveCmsPage() {
        var data = {
            PageName: this.state.pageName,
            PageDescription: this.state.pageDescription
        };

        console.log("Adding...")

        cmspageService.create(data)
            .then(response => {
                this.setState({
                    pageId: response.data.id,
                    pageName: response.data.pageName,
                    pageDescription: response.data.pageDescription,
                    submitted: true
                });
                console.log(response.data);
                alert("Page created successfully!")
                this.props.history.push('/cms')
            })
            .catch(e => {
                console.log(e);
            });
    }

    newCmsPage() {
        this.setState({
            pageId: null,
            pageName: "",
            pageDescription: "",
            submitted: false
        });
    }

    render() {
        return (
            <div className="submit-form">
                <h4>Create Page</h4>
                {this.state.submitted ? (
                    <div>
                        <h4>You created a new page successfully!</h4>
                        <Link to={"/cms"}>Back to listing</Link>
                    </div>
                ) : (
                    <div>
                        <div className="form-group">
                            <label htmlFor="title">Page Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                required
                                value={this.state.pageName}
                                onChange={this.onChangePageName}
                                name="title"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                required
                                value={this.state.pageDescription}
                                onChange={this.onChangePageDescription}
                                name="description"
                            />
                        </div>

                            <button onClick={() =>this.saveCmsPage()} className="btn btn-success">
                            Submit
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
